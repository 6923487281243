import { PlayIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';

export const CircularFloater = (props: {
  open: boolean;
  onClose: Function;
  videoUrl?: string;
  onVideoPlay?: Function;
  onVideoPaused?: Function;
  onVideoEnded?: Function;
}) => {
  const { open, onClose, videoUrl, onVideoPlay, onVideoPaused, onVideoEnded } =
    props;

  const [videoPlaying, setVideoPlaying] = useState(false);

  const videoRef = useRef<any>(null);

  function playVideo() {
    setVideoPlaying(true);
    onVideoPlay?.();
    videoRef.current.play();
    videoRef.current.addEventListener('paused', () => {
      setVideoPlaying(false);
      onVideoPaused?.();
    });
    videoRef.current.addEventListener('ended', () => {
      setVideoPlaying(false);
      onVideoEnded?.();
    });
  }

  function pauseVideo() {
    setVideoPlaying(false);
    videoRef.current.pause();
  }

  useEffect(() => {}, []);

  return open ? (
    <div className="fixed w-64 h-64 flex bottom-4 right-4 z-10">
      <video
        ref={videoRef}
        className='controls object-cover bg-blue-immigo rounded-full overflow-hidden"'
        onClick={pauseVideo}
        src={videoUrl}
      ></video>
      {!videoPlaying ? (
        <div
          className="bg-opacity-40 fixed w-64 h-64 bg-blue-400 rounded-full cursor-pointer flex flex-col items-center justify-center text-white p-14 text-xl text-center font-bold"
          onClick={playVideo}
        >
          <PlayIcon className="w-10 h-10" />
          <span>Message from Co-founder</span>
        </div>
      ) : null}
      <XCircleIcon
        onClick={() => onClose()}
        className="absolute w-5 h-5 cursor-pointer text-gray-500 hover:text-gray-300"
      />
    </div>
  ) : (
    <></>
  );
};
