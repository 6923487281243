import { useEffect, useState } from 'react';
import { RankingEntry } from './RankingEntry';
import { returnUserByUID } from '../../../firebase/configuration';
import { UserData } from '../../../types/user';
import { cx } from 'classix';
import { getNumberRangeContainingN } from '../../../util/data';
import {
  getPhraseRanking,
  getStreakRanking,
} from '../../../firebase/ranking/ranking';
import { RankLoader } from './RankLoader';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import ReactTooltip from 'react-tooltip';
import { ScreenShotComponentButton } from '../../../components/buttons/screenShot/screenShotComponentButton';
import { trackHomeRankingContainer } from './analytics';
import NoBorderTabs from '../../../components/tabs/noBorderTabs/NoBorderTabs';
import { FireIcon } from '@heroicons/react/24/solid';

type UserMap = {
  [key: string]: UserData;
};

type UserPhraseEntry = {
  [key: string]: string[] | number;
};

const defaultLimit = 5;

export const RankingContainer = (props: { uid: string; limit?: number }) => {
  const { uid, limit } = props;
  const [userMap, setUserMap] = useState<UserMap | undefined>(undefined);
  const [ranking, setRanking] = useState<any>();
  const [previousRanking, setPreviousRanking] = useState<any>();
  const [selectedTab, setSelectedTab] = useState(0);
  const hardLimit = limit ?? defaultLimit;

  async function fetchRanking() {
    const newRankingData =
      selectedTab === 0 ? await getStreakRanking() : await getPhraseRanking();
    const newRanking = newRankingData?.currentRanking;
    const oldRanking = newRankingData?.previousRanking;
    setPreviousRanking(oldRanking);
    setRanking(newRanking);
    const myRanking = newRanking?.sortedArray?.indexOf(uid);
    const { startIndex, endIndex } = getNumberRangeContainingN(
      myRanking,
      hardLimit,
      newRanking?.sortedArray?.length,
      1,
    );

    console.log('newRanking does not exist');
    if (!newRanking) return;

    const newUserMap: any = {};
    await Promise.all(
      newRanking?.sortedArray
        ?.slice(startIndex, endIndex)
        ?.map(async (uid: string) => {
          const user = await returnUserByUID(uid);
          newUserMap[uid] = user;
        }),
    );
    setUserMap(newUserMap);
  }

  function renderRanking() {
    if (userMap && ranking) {
      const myRanking = ranking?.sortedArray?.indexOf(uid);
      const { startIndex, endIndex } = getNumberRangeContainingN(
        myRanking,
        hardLimit,
        ranking?.sortedArray?.length,
        1,
      );

      return ranking.sortedArray
        .slice(startIndex, endIndex)
        .map((uid_: string, index: number) => {
          const user = userMap[uid_];
          if (user) {
            const previousCount = (
              (previousRanking?.userMap as UserPhraseEntry)?.[uid_] as string[]
            )?.length;
            const currentCount =
              ((ranking?.userMap as UserPhraseEntry)?.[uid_] as string[])
                ?.length ?? (ranking?.userMap as UserPhraseEntry)?.[uid_];
            const previousRank = previousRanking?.sortedArray?.indexOf(uid_);
            const currentRank = ranking?.sortedArray?.indexOf(uid_);

            return (
              <div className={cx(index < hardLimit - 1 ? 'border-b' : null)}>
                <RankingEntry
                  id={uid_}
                  onAvatarClick={() => {
                    trackHomeRankingContainer(
                      'Clicked user profile bubble on ranking container',
                      {
                        id: uid_,
                      },
                    );
                    window.open(
                      `${window.location.origin}/profile/${uid_}`,
                      '_blank',
                    );
                  }}
                  rankingChange={
                    !previousRank || previousRank === currentRank
                      ? 'same'
                      : previousRank > currentRank
                      ? 'increased'
                      : 'decreased'
                  }
                  profileImageUrl={user.profilePictureLink}
                  highlight={uid === uid_}
                  name={user.name}
                  rank={startIndex + index + 1}
                  count={currentCount}
                  previousCount={previousCount}
                  customIcon={
                    selectedTab === 1 ? (
                      <FireIcon className="w-4 h-4 m-auto text-yellow-300 mr-1" />
                    ) : undefined
                  }
                />
              </div>
            );
          }
        });
    }
  }

  useEffect(() => {
    fetchRanking();
  }, [selectedTab]);

  return (
    <div
      id="ranking-container"
      className=" rounded-lg shadow w-full py-6 px-4 relative mb-2 bg-white"
    >
      <div className="text-base font-semibold text-gray-900 mb-2 flex items-center">
        <div className="md:max-w-full max-w-[150px]">🏆 Leaderboard</div>
        <div data-html2canvas-ignore="true">
          <ReactTooltip
            className="font-bold rounded-md max-w-[300px]"
            backgroundColor="#009eff"
          />
          <InformationCircleIcon
            className="w-5 h-5 text-gray-300 my-auto ml-2 cursor-pointer"
            data-tip={`Phrases learned will only count towards the ranking if you have reviewed and attended the class.
                        Your streak represents how many weeks in a row you've taken at least one class, which resets if you skip a week.
                        All rankings will update every midnight PST.
                      `}
          />
        </div>
        <div className="ml-auto">
          <ScreenShotComponentButton
            componentID="ranking-container"
            onClick={() => {
              trackHomeRankingContainer(
                'Clicked share button on ranking container at home',
                {},
              );
            }}
          />
        </div>
      </div>
      <NoBorderTabs
        tabs={['🔥 Streak']}
        selectedIndex={selectedTab}
        onTabClicked={(i: number) => {
          setSelectedTab(i);
          trackHomeRankingContainer('Clicked tab on the ranking container', {
            clickedTab: ['🔥 Streak'][i],
          });
        }}
      />
      {ranking ? renderRanking() : <RankLoader />}
    </div>
  );
};
