import {
  CheckCircleIcon,
  DocumentMagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { cx } from 'classix';
import moment from 'moment';
import { BookingData } from '../../../firebase/subscription/subscription';

import { trackBookingAnalytics } from '../analytics';
import { Avatar } from '../../../components/avatar/avatar';
import AvatarGroupStacked from '../../../components/avatar/avatarGroupStacked/avatarGroupStacked';
import { getFirstNameFromFullName } from '../../../util/helper';
import { capitalizeFirstLetter } from '../../../util/standardization';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { tagToColorStyle } from '../../../util/bookings';
import { useEffect, useState } from 'react';

export interface BookingEventObject extends BookingData {
  chosenDateNumber?: number; // Option to hide
  chosen?: boolean; // Chosen or not
  onClick?: Function;
  onPreviewClick?: Function;
  enablePreview?: boolean;
  id: string;
  alreadyBooked?: boolean;
  alreadyTaken?: boolean;
  columnIndex?: number;
  bookings?: Array<BookingData>;
  recommended?: boolean;
  disabled?: boolean;
  full?: boolean;
  disableColumn?: boolean;
  hideAttendees?: boolean;
  show?: boolean;
}

export function EventBox({
  title,
  alreadyBooked,
  alreadyTaken,
  durationMili,
  startMili,
  chosenDateNumber,
  columnIndex,
  chosen,
  recommended,
  bookerList,
  teacher,
  enablePreview,
  onClick,
  onPreviewClick,
  disabled,
  full,
  disableColumn,
  hideAttendees,
  tag,
  recLevel,
}: BookingEventObject) {
  const dateNumber = columnIndex;
  const colStyleMap = [
    'md:col-start-1',
    'md:col-start-2',
    'md:col-start-3',
    'md:col-start-4',
    'md:col-start-5',
    'md:col-start-6',
    'md:col-start-7',
    '',
  ];

  return (
    <>
      <li
        className={cx(
          !disabled ? `cursor-pointer` : '',
          `relative relative mt-px flex md:min-h-[170px] min-h-[155px] min-w-[150px] ${
            colStyleMap[disableColumn ? 7 : (dateNumber ?? 1) - 1]
          }
      ${
        chosenDateNumber === moment(startMili).date() || disableColumn
          ? 'flex'
          : 'sm:flex hidden'
      }`,
        )}
      >
        {chosen ? (
          <div className="h-[155px]">
            <CheckCircleIcon className="flex-shrink-0 w-5 h-5 text-white" />
          </div>
        ) : null}
        <div
          className={cx(
            'pt-4',
            recommended ? 'border border-blue-immigo-lighter border-2' : '',
            'group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5',
            onPreviewClick ? 'pr-12 md:pr-0' : '',
            chosen && !disabled
              ? 'bg-green-500 hover:bg-green-600'
              : disabled
              ? 'bg-gray-300'
              : 'bg-blue-50 hover:bg-blue-100',
          )}
          onClick={() => {
            if (onClick && !disabled) onClick();
          }}
        >
          <p
            className={cx(
              'text-center px-7',
              chosen && !disabled
                ? 'text-white'
                : disabled
                ? 'text-gray-500'
                : 'text-blue-500 group-hover:text-blue-700',
            )}
          >
            <time dateTime={moment(startMili).format()}>
              {`${moment(startMili).format('h:mmA')} - ${moment(startMili)
                .add(durationMili)
                .format('h:mm A MMM D')}`}
            </time>
          </p>
          <p
            className={cx(
              'block font-semibold text-xs',
              chosen && !disabled
                ? 'text-white'
                : disabled
                ? 'text-gray-500'
                : 'text-blue-700',
            )}
          >
            {teacher ? (
              <div className="md:mr-0 mr-2 flex justify-center min-w-[30px] my-0 md:my-2 items-center">
                <Avatar photoUrl={teacher?.profilePictureLink} />
                <div className="ml-2">
                  {`Coach ${capitalizeFirstLetter(
                    getFirstNameFromFullName(teacher?.name ?? ''),
                  )}`}
                </div>
              </div>
            ) : null}
            <div className="my-2 flex justify-center">
              {alreadyBooked ? (
                <span className="flex justify-center items-center">
                  <span>
                    <ExclamationTriangleIcon className="mr-2 text-yellow-500 h-5 w-5" />
                  </span>
                  <span className="text-yellow-700">
                    {'You already booked this topic!'}
                  </span>
                </span>
              ) : alreadyTaken ? (
                <span className="flex justify-center items-center">
                  <span>
                    <ExclamationTriangleIcon className="mr-2 text-yellow-500 h-5 w-5" />
                  </span>
                  <span className="text-yellow-700">
                    {'You already took this topic!'}
                  </span>
                </span>
              ) : null}
            </div>
            <span className="text-center mx-auto">{title}</span>
          </p>
          {!hideAttendees ? (
            bookerList?.length ? (
              <div className="mx-auto my-2">
                <AvatarGroupStacked
                  imageLinks={bookerList?.map(
                    (booker) => booker.profilePictureLink,
                  )}
                  names={bookerList?.map((booker) => booker.name)}
                  nationalities={bookerList.map(
                    (booker) => booker.nationality ?? '',
                  )}
                  ids={bookerList?.map((booker) => booker.id)}
                  limit={6}
                />
              </div>
            ) : (
              <div className="text-gray-500 my-2">
                Be the first student to book!
              </div>
            )
          ) : null}
        </div>
        {onPreviewClick ? (
          <div className="absolute right-0 py-1 h-full min-w-[50px]">
            <div
              className={cx(
                enablePreview
                  ? 'bg-[#009FB7] hover:bg-[#00B5D1] cursor-pointer'
                  : 'bg-gray-400 cursor-default',
                'h-full flex items-center justify-center rounded-r-lg',
              )}
              onClick={() => {
                if (enablePreview) onPreviewClick();
              }}
            >
              <DocumentMagnifyingGlassIcon className="w-8 h-8 text-[#EFF6FF]" />
            </div>
          </div>
        ) : null}
        {recommended && !disabled && !full ? (
          <span className="-top-2 right-0 absolute inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-blue-700/10">
            Recommended
          </span>
        ) : full ? (
          <span className="-top-2 right-0 absolute inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700/10">
            Full
          </span>
        ) : null}
        {tag ? (
          <span
            className={cx(
              tagToColorStyle(tag),
              '-top-2 left-0 absolute inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
            )}
          >
            {capitalizeFirstLetter(tag)}
          </span>
        ) : null}
        {recLevel ? (
          <span className="top-4 left-0 absolute inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-blue-700/10">
            {capitalizeFirstLetter(recLevel)}
          </span>
        ) : null}
      </li>
    </>
  );
}

export default function BookingEventContainer(props: {
  events: Array<BookingEventObject>;
  chosenDateMoment: moment.Moment;
  weekDayMoments: Array<moment.Moment>; // Ordered 7 days since todays date
  chosenSchedule: Array<BookingEventObject>;
  setChosenSchedule: Function;
}) {
  const {
    events,
    chosenDateMoment,
    chosenSchedule,
    setChosenSchedule,
    weekDayMoments,
  } = props;

  const [showEvents, setShowEvents] = useState<boolean>(true);
  const [eventList, setEventList] = useState<Array<any>>([]);

  function renderEvents() {
    events.sort((a: BookingEventObject, b: BookingEventObject) => {
      return a.startMili - b.startMili;
    }); // Sort events by earliest

    let showFlag = false;
    const eventList_ = events.map(
      (event: BookingEventObject, index: number) => {
        let colIndex = 1;
        weekDayMoments.forEach((day: moment.Moment, index: number) => {
          if (day.date() === moment(event.startMili).date()) colIndex = index;
        });
        let eventIndexInChosenSchedule: number | undefined;
        const isChosen =
          chosenSchedule.filter((c, index) => {
            const chosen = event.id === c.id && event.startMili === c.startMili;
            if (chosen) eventIndexInChosenSchedule = index;
            return chosen;
          }).length > 0;
        if (
          chosenDateMoment.date() === moment(event.startMili).date() ||
          event.show
        ) {
          showFlag = true;
        }
        return (
          <EventBox
            key={event.id}
            {...event}
            recommended={
              event.recommended &&
              event?.bookings?.length !== undefined &&
              event?.bookings?.length > 0 &&
              event?.bookings?.length < 6
            }
            columnIndex={colIndex + 1}
            chosenDateNumber={chosenDateMoment.date()}
            chosen={isChosen}
            hideAttendees
            onClick={() => {
              if (event?.onClick) event?.onClick?.();
              else {
                let newSchedules: Array<BookingEventObject> = [];
                if (isChosen) {
                  trackBookingAnalytics('Deselected a class in pricing page', {
                    classID: event.id,
                  });
                  newSchedules = [...chosenSchedule];
                  if (eventIndexInChosenSchedule !== undefined) {
                    newSchedules.splice(eventIndexInChosenSchedule, 1);
                  }
                } else {
                  trackBookingAnalytics('Selected a class in pricing page', {
                    classID: event.id,
                  });
                  if (event?.id) newSchedules = [...chosenSchedule, event];
                }
                setChosenSchedule(newSchedules);
              }
            }}
          />
        );
      },
    );

    setShowEvents(showFlag);
    setEventList(eventList_);
    return eventList;
  }

  useEffect(() => {
    renderEvents();
  }, [chosenDateMoment, events, chosenSchedule]);

  return (
    <div className="grid md:grid-cols-7 gap-2 pl-1 pt-3 flex-auto grid-flow-row-dense">
      {showEvents ? (
        eventList
      ) : (
        <span className="text-sm text-gray-500 my-2 col-span-7">
          No classes available this day, please click another day!
        </span>
      )}
    </div>
  );
}
