import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createSetupIntent, getClientSecret } from '../../../api';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '../../buttons/primary';
import { useSelector } from 'react-redux';
import { UserData } from '../../../types/user';

const STRIPE_KEY =
  process.env.REACT_APP_ENV === 'PROD'
    ? process.env.REACT_APP_LIVE_PUBLISHABLE_KEY
    : process.env.REACT_APP_TEST_PUBLISHABLE_KEY;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_KEY ?? '');

const PaymentMethodElement = (props: {
  clientSecret: string;
  returnUrl: string;
}) => {
  const [loading, setLoading] = useState(false);
  const { clientSecret, returnUrl } = props;
  const stripe = useStripe();
  const elements = useElements() ?? undefined;

  async function onSubmit() {
    setLoading(true);
    const options = {
      clientSecret: clientSecret, //intent
      // Fully customizable with appearance API.
    };

    elements?.submit();

    const { error, paymentMethod }: any = await stripe?.confirmSetup({
      elements,
      clientSecret: clientSecret ?? '',
      confirmParams: {
        return_url: returnUrl,
      },
    });
    setLoading(false);
  }
  return (
    <div>
      <PaymentElement />
      <PrimaryButton onClick={onSubmit} text="Submit" loading={loading} />
    </div>
  );
};

const PaymentMethodForm = (props: { returnUrl: string }) => {
  const { returnUrl } = props;
  const [client_secret, setClientSecret] = useState<undefined | string>();
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  async function onInit() {
    const data = await createSetupIntent(
      'card',
      userData.name,
      userData.email,
      userData.id,
      userData.customerID,
    );

    const seti = data?.data?.setupIntent;
    const clientSecret = seti?.client_secret;

    setClientSecret(clientSecret);
    console.log(data);
  }

  useEffect(() => {
    onInit();
  }, []);

  return (
    <>
      {client_secret ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: client_secret,
          }}
        >
          <PaymentMethodElement
            clientSecret={client_secret}
            returnUrl={returnUrl}
          />
        </Elements>
      ) : null}
    </>
  );
};

export default PaymentMethodForm;
