import cx from 'classix';

export const RadialProgress = (props: {
  color: string;
  percentage: number;
  backgroundColor?: string;
}) => {
  const { color, percentage, backgroundColor } = props;
  return (
    <div className="w-20 h-20">
      <div className="relative size-40">
        <svg
          className="size-full -rotate-90"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className={'stroke-current'}
            style={{
              color: backgroundColor,
            }}
            stroke-width="2"
          ></circle>
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className={'stroke-current'}
            style={{
              color: color,
            }}
            stroke-width="2"
            stroke-dasharray="100"
            stroke-dashoffset={100 - percentage}
            stroke-linecap="round"
          ></circle>
        </svg>

        <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <span
            className={'text-center text-xl font-bold'}
            style={{
              color: color,
            }}
          >
            {`${percentage}%`}
          </span>
        </div>
      </div>
    </div>
  );
};
