import mixpanel from '../../analytics/mixpanel';

export type LayOutEvents =
  | 'Clicked additional info navbar button'
  | 'Clicked courses navbar button'
  | 'Clicked home navbar button'
  | 'Clicked my reports navbar button'
  | 'Clicked meeting board navbar button'
  | 'Clicked availability navbar button'
  | 'Clicked start a video call navbar button'
  | 'Clicked subscription navbar button'
  | 'Clicked my classes navbar button'
  | 'Clicked certificates navbar button'
  | 'Clicked plans navbar button'
  | 'Clicked learning path navbar button'
  | 'Clicked progress navbar button'
  | 'Welcome video floater popped up'
  | 'Welcome video floater closed'
  | 'Welcome video floater played'
  | 'Welcome video floater paused'
  | 'Welcome video floater ended';

type UserData = {
  userID?: string;
  userName?: string;
  userEmail?: string;
};

export const trackLayOutEvent = (event: LayOutEvents, data: UserData = {}) => {
  mixpanel.track(event, data);
};
