import { PlusIcon } from '@heroicons/react/24/outline';
import { PaymentMethod } from '../../../types/payment';
import StackedPaymentRadio from '../../forms/stackedPaymentRadio/stackedPaymentRadio';
import HorizontalMessageModal from '../horizontalMessageModal/horizontalMessageModal';
import PaymentForm from '../../payment/paymentForm/paymentForm';
import HorizontalModalTemplate from '../templates/horizontalModalTemplate/horizontalModalTemplate';
import VerticalMessageModal from '../verticalMessageModal/verticalMessageModal';
import { useState } from 'react';

export const PaymentMethodsModal = (props: {
  paymentMethods: Array<PaymentMethod>;
  returnUrl: string;
  defaultID?: string;
  onSave: Function;
  onDelete: Function;
  isOpen: boolean;
  onClose: Function;
}) => {
  const {
    paymentMethods,
    defaultID,
    onSave,
    isOpen,
    onClose,
    onDelete,
    returnUrl,
  } = props;

  const [openNewPaymentModal, setOpenNewPaymentModal] = useState(false);
  const [selectedID, setSelectedID] = useState<string | undefined>();

  function onMethodChosen(id: string) {
    setSelectedID(id);
    console.log(id);
  }

  async function onSaveClick() {
    await onSave(selectedID);
  }

  return (
    <>
      <VerticalMessageModal
        mainMessage="Add a new payment  method"
        subMessage="You can add a new payment method below"
        theme="default"
        icon="pencil"
        onCloseModal={() => {
          setOpenNewPaymentModal(false);
        }}
        isOpen={openNewPaymentModal}
      >
        <div className="flex items-center justify-center my-2">
          <PaymentForm returnUrl={returnUrl} />
        </div>
      </VerticalMessageModal>
      <HorizontalMessageModal
        mainMessage="Saved payment methods"
        subMessage="You can update your payment methods below"
        theme="default"
        icon="credit-card"
        buttonText="Save"
        onButtonClick={onSaveClick}
        onCloseModal={onClose}
        isOpen={isOpen}
      >
        <div className="py-4">
          <StackedPaymentRadio
            onChosen={onMethodChosen}
            onDelete={onDelete}
            paymentMethods={paymentMethods}
            defaultChosenID={defaultID}
          />
        </div>
        <div
          onClick={() => {
            setOpenNewPaymentModal(true);
          }}
          className="flex items-center text-gray-600 hover:text-blue-immigo text-sm cursor-pointer"
        >
          <span>
            <PlusIcon className="w-4 h-4 mr-2" />
          </span>
          Add a new payment method
        </div>
      </HorizontalMessageModal>
    </>
  );
};
