import { useEffect, useState } from 'react';
import {
  getCustomerSubscriptionInformation,
  getSubscriptionProductInformation,
  subscribeUserMembership,
} from '../../api';
import {
  classesSubscriptionTiers,
  classesSubscriptionTiersYearly,
  couponMap,
} from '../../config/stripe';
import { PricingOptionBox } from '../../features/Booking/pricingOptionBox';
import { useSelector } from 'react-redux';
import { TotalTable } from '../../components/registration/paymentpage/totaltable';
import { frequenciesPricing } from '../PricingPage/PricingPage';
import { SparkleButton } from '../../components/buttons/sparkle/sparkleButton';
import { SecondaryBlueButton } from '../../components/buttons/secondaryBlue';
import GreenAlert from '../../components/alerts/greenAlert';
import {
  changePlan,
  createStripeCustomer,
  getStripeCustomer,
} from '../../api/payment';
import { capitalizeFirstLetter } from '../../util/standardization';
import { PaymentMethodCardSimple } from '../../components/payment/paymentMethodCardSimple/PaymentMethodCardSimple';
import {
  CalendarIcon,
  CheckCircleIcon,
  CreditCardIcon,
  ShoppingBagIcon,
} from '@heroicons/react/24/outline';
import SmallCardsRadio from '../../components/forms/smallCardsRadio/SmallCardsRadio';
import { weekInMili } from '../../usefuldata/mili';
import moment from 'moment';
import TextInput from '../../components/forms/textInput';
import { PricingOptionBoxLoader } from '../../features/Booking/loaders/pricingOptionBoxLoader';
import VerticalMessageModal from '../../components/modals/verticalMessageModal/verticalMessageModal';
import BlueAlert from '../../components/alerts/blueAlert';
import { PrimaryButton } from '../../components/buttons/primary';
import { trackPlanChangePageEvents } from '../../features/PlanChangePage/analytics';
import { updateUserData } from '../../firebase/users/users';

export const PlanChangePage = () => {
  const userData = useSelector((statea: any) => statea.sessionState.userData);

  const [currency, setCurrency] = useState('usd');
  const [priceID, setPriceID] = useState();
  const [subOption, setSubOption] = useState<undefined | number>(undefined);
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<any>(undefined);
  const [customerID, setCustomerID] = useState<string | undefined>();
  const [currentPriceID, setCurrentPriceID] = useState<string | undefined>();
  const [currentPriceIndex, setCurrentPriceIndex] = useState<
    undefined | number
  >(undefined);

  const [subID, setSubID] = useState<string | undefined>();
  const [subItemID, setSubItemID] = useState<string | undefined>();
  const [nextBilling, setNextBilling] = useState<number | undefined>();
  const [billedOption, setBilledOption] = useState('now');
  const [frequency, setFrequency] = useState<'year' | 'week' | undefined>();

  const [loading, setLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [fail, setFail] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const [couponCode, setCouponCode] = useState('');

  const tiers =
    frequenciesPricing[0].value === 'annually'
      ? classesSubscriptionTiersYearly
      : classesSubscriptionTiers;

  const finalPrice =
    subOption !== undefined ? tiers[subOption].price[currency] : 0;

  const realPrice =
    subOption !== undefined ? tiers[subOption].price[currency] : 0;

  const [totalPrice, setTotalPrice] = useState(
    subOption !== undefined
      ? finalPrice -
          (couponMap[couponCode ?? '']?.[tiers[subOption].id]?.amount?.[
            currency
          ] ?? 0)
      : 0,
  );

  const [totalTableItems, setTotalTableItems] = useState(
    subOption !== undefined
      ? [
          {
            name: `${tiers[subOption].name} per 4 weeks`,
            price: tiers[subOption].price[currency],
            isDiscount: false,
          },
        ]
      : [],
  );

  const urlParams = new URLSearchParams(window.location.search);
  const subOptionParam = urlParams.get('subOption');
  const couponCodeParam = urlParams.get('couponCode');

  async function updateSubscriptionData(
    customerID: string,
    userID: string,
    email: string,
    name: string,
  ) {
    console.log('updaing sub');
    setCustomerID(customerID);
    const subscriptionInfo = await getCustomerSubscriptionInformation(
      customerID,
    );
    let customer = await getStripeCustomer(customerID);

    // Create a customer profile if it doesn't exist
    if (!customer) {
      const customer = await createStripeCustomer(userID, email, name);
      await updateUserData(userID, { customerID: customer.id });
    }

    if (
      customer?.invoice_settings?.default_payment_method &&
      customer?.paymentMethods
    ) {
      const defaultMethod = customer.paymentMethods.find(
        (method: any) =>
          method.id === customer?.invoice_settings?.default_payment_method,
      );
      if (defaultMethod?.card) {
        if (defaultMethod?.card) {
          defaultMethod.card.id = defaultMethod?.id;
          setDefaultPaymentMethod(defaultMethod.card);
        }
        setDefaultPaymentMethod(defaultMethod.card);
      }
    }

    if (subOptionParam !== null && couponCodeParam !== undefined)
      setSubOption(parseInt(subOptionParam));
    if (couponCodeParam !== null && couponCodeParam !== undefined)
      setCouponCode(couponCodeParam);

    if (!subscriptionInfo) return;

    if (
      subscriptionInfo &&
      subscriptionInfo.plan &&
      subscriptionInfo.plan.product
    ) {
      setSubID(subscriptionInfo.id);
      setSubItemID(subscriptionInfo.items?.data?.[0]?.id);

      if (subscriptionInfo.plan.interval === 'year') setFrequency('year');
      else if (subscriptionInfo.plan.interval === 'week') setFrequency('week');

      const productInfo = await getSubscriptionProductInformation(
        subscriptionInfo.plan.product,
      );
      subscriptionInfo.id = subscriptionInfo.subID;
      subscriptionInfo.plan.name = productInfo?.name;
      setCurrentPriceID(subscriptionInfo.plan.id);

      if (subscriptionInfo.customerData?.paymentMethods) {
        let newDefault =
          subscriptionInfo.customerData?.paymentMethods?.[0]?.card;
        subscriptionInfo.customerData?.paymentMethods.forEach((p: any) => {
          if (p.id === customer?.invoice_settings?.default_payment_method) {
            newDefault = p?.card;
          }
        });
        setDefaultPaymentMethod(newDefault);
      }

      // Update next billing date
      let nextPeriod = (subscriptionInfo?.current_period_end ?? 0) * 1000;
      // Paused
      if (subscriptionInfo?.pause_collection?.resumes_at) {
        while (
          nextPeriod <
          subscriptionInfo.pause_collection.resumes_at * 1000
        ) {
          nextPeriod += 4 * weekInMili;
        }
      }
      setNextBilling(nextPeriod);
    }

    const productInfo = await getSubscriptionProductInformation(
      subscriptionInfo.plan.product,
    );

    const _currency = subscriptionInfo?.currency;
    const _priceID = subscriptionInfo?.plan?.id;

    const plan = classesSubscriptionTiers.find((tier) => tier.id === _priceID);

    setCurrency(_currency);
    setPriceID(_priceID);
  }

  async function onUpgrade() {
    setLoading(true);
    trackPlanChangePageEvents('Visited plan change page');
    //upgrade right now mode
    if (subID && subItemID && subOption !== undefined) {
      const resp = await changePlan(
        subID,
        subItemID,
        tiers[subOption].id,
        couponMap[couponCode ?? '']?.[tiers[subOption].id]?.coupon,
        billedOption === 'later',
        false,
      );
      console.log(resp);
      if (resp?.subscription) {
        setSuccessModalOpen(true);
      } else {
        setFail(true);
      }
    } else if (!subID && subOption !== undefined && defaultPaymentMethod) {
      const resp = await subscribeUserMembership(
        defaultPaymentMethod.id,
        userData.email,
        userData.name,
        tiers[subOption].id,
        userData.id,
        customerID,
        undefined,
        couponMap[couponCode ?? '']?.[tiers[subOption].id]?.coupon,
        'usd',
      );
      console.log(resp);
      const data = resp?.data;
      if (data?.client_secret) {
        setSuccessModalOpen(true);
      } else {
        setFail(true);
      }
    } else {
      if (!defaultPaymentMethod) setError('Please add a valid payment method.');
      console.log(subItemID);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (userData?.customerID && userData?.id)
      updateSubscriptionData(
        userData.customerID,
        userData.id,
        userData.email,
        userData.name,
      );
    if (userData?.couponCode) setCouponCode(userData.couponCode);
  }, [userData]);

  useEffect(() => {
    if (subOption !== undefined) {
      const items = [
        {
          name: `${tiers[subOption].name} per 4 weeks`,
          price: finalPrice,
          isDiscount: false,
        },
      ];

      if (
        couponCode &&
        couponMap[couponCode]?.[tiers[subOption].id]?.amount?.[currency]
      ) {
        items.push({
          name: couponCode,
          price:
            couponMap[couponCode]?.[tiers[subOption].id]?.amount?.[currency] ||
            1,
          isDiscount: true,
        });
      }
      setTotalTableItems(items);
      setTotalPrice(
        tiers[subOption].price[currency] -
          (couponMap[couponCode ?? '']?.[tiers[subOption].id]?.amount?.[
            currency
          ] ?? 0),
      );
    }
    tiers.forEach((tier, index) => {
      if (currentPriceID === tier.id) setCurrentPriceIndex(index);
    });
  }, [subOption, couponCode]);

  useEffect(() => {
    trackPlanChangePageEvents('Visited plan change page');
  }, []);

  return (
    <div>
      <VerticalMessageModal
        mainMessage="You upgraded your plan!"
        subMessage={`You successfully upgraded your learning plan! ${
          billedOption === 'now'
            ? 'Your plan has upgraded as of today, and we have updated your class credits accordingly.'
            : `Your learning plan will be upgraded in your next billing period ${
                nextBilling ? moment(nextBilling).format('MMMM DD, YYYY') : ''
              }`
        }`}
        theme={'success'}
        buttonText={'Continue'}
        onButtonClick={() => {
          setSuccessModalOpen(false);
          window.location.href = '/home';
        }}
        onCloseModal={() => {
          setSuccessModalOpen(false);
          window.location.href = '/home';
        }}
        isOpen={successModalOpen}
      />
      <div className="md:flex">
        {userData ? (
          <div className="flex w-full md:justify-between md:mb-0 mb-2">
            {subOption === undefined ? (
              (frequency !== undefined && frequency !== 'year') || !subID ? (
                classesSubscriptionTiers.map((tier, index) => {
                  const price_ = tier.price[currency ?? 'usd']
                    ? tier.price[currency ?? 'usd']
                    : tier.price['usd'];
                  const discount_ = tier.discount?.[currency ?? 'usd']
                    ? tier.discount?.[currency ?? 'usd']
                    : tier.discount?.['usd'];
                  const coupon =
                    couponMap[couponCode ?? '']?.[tier.id]?.amount?.[
                      currency ?? 'usd'
                    ];

                  const totalPrice = price_ - (coupon ?? 0);
                  const discount = coupon
                    ? price_ - totalPrice + (discount_ ?? 0)
                    : discount_;

                  return (
                    <div className="max-w-[380px]">
                      <PricingOptionBox
                        description={tier.description}
                        price={totalPrice}
                        id={tier.id}
                        classesCount={tier.classesCount}
                        current={currentPriceID === tier.id}
                        currency={currency}
                        features={tier.features}
                        discount={discount}
                        hideSelect={subOption !== undefined}
                        onSelect={() => {
                          trackPlanChangePageEvents(
                            'Selected learning plan in change plan page',
                            {
                              subOption: index,
                            },
                          );
                          //   setClicked(true);
                          setSubOption(index);
                          //   trackBookingAnalytics('Chose learning plan at pricing page', {
                          //     subOption: index,
                          //   });
                          //   setTimeout(() => {
                          //     !ready ? setPage(7) : setPage(8);
                          //   }, animationDuration);
                        }}
                      />
                    </div>
                  );
                })
              ) : frequency !== 'year' ? (
                <BlueAlert text="To change your yearly plan please contact ricardo@immigo.io!" />
              ) : null
            ) : (
              <div>
                <PricingOptionBox
                  description={tiers[subOption].description}
                  price={totalPrice}
                  id={tiers[subOption].id}
                  classesCount={tiers[subOption].classesCount}
                  currency={currency}
                  features={tiers[subOption].features}
                  discount={
                    couponMap[couponCode ?? '']?.[tiers[subOption].id]
                      ?.amount?.[currency ?? 'usd']
                      ? (tiers[subOption].price[currency ?? 'usd']
                          ? tiers[subOption].price[currency ?? 'usd']
                          : tiers[subOption].price['usd']) -
                        totalPrice +
                        ((tiers[subOption].discount?.[currency ?? 'usd']
                          ? tiers[subOption].discount?.[currency ?? 'usd']
                          : tiers[subOption].discount?.['usd']) ?? 0)
                      : tiers[subOption].discount?.[currency ?? 'usd']
                      ? tiers[subOption].discount?.[currency ?? 'usd']
                      : tiers[subOption].discount?.['usd']
                  }
                  hideSelect={subOption !== undefined}
                />
                <div className="shadow bg-white rounded-xl px-8 py-6 mt-2 min-w-0 md:min-w-[600px]">
                  <div className="md:text-xl text-lg mb-2">
                    {'Discount code'}
                  </div>
                  <div className="max-w-[450px] flex items-center">
                    <TextInput value={couponCode} onChange={setCouponCode} />
                    {couponCode && couponMap[couponCode] ? (
                      <div className="flex-shrink-0 mb-5 ml-2">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full flex md:justify-between">
            {classesSubscriptionTiers.map((tier) => {
              return <PricingOptionBoxLoader key={tier.id + '-loader'} />;
            })}
          </div>
        )}

        {subOption !== undefined && customerID ? (
          <div className="p-8 shadow bg-white rounded-2xl flex flex-col w-full md:ml-2 md:mt-0 mt-">
            <GreenAlert
              text={
                billedOption === 'later'
                  ? `Class credits will be updated at the end of the current billing period ${moment(
                      nextBilling,
                    ).format('MMMM DD, YYYY')}`
                  : !subID
                  ? 'Your class credits will be updated right after you start your subscription.'
                  : 'Class credits will be updated right away after you are billed for the subscription.'
              }
            />
            <div className="font-bold text-gray-600 flex items-center mt-4">
              <CreditCardIcon className="h-5 w-5 mr-2" /> Payment method
            </div>
            <div className="mt-4">
              <PaymentMethodCardSimple
                brand={defaultPaymentMethod?.brand}
                lastFour={defaultPaymentMethod?.last4}
                expMonth={defaultPaymentMethod?.exp_month}
                expYear={defaultPaymentMethod?.exp_year}
                customerID={customerID}
                onAction={() => {}}
                onActionComplete={() => {}}
                returnUrl={`${
                  window.location.origin
                }/plans?subOption=${subOption}${
                  couponCode ? `&couponCode=${couponCode}` : ''
                }`}
              />
            </div>

            {subID ? ( // Only allow when you are changing plan not resubscribing
              <>
                <div className="font-bold text-gray-600 flex items-center mt-3">
                  <CalendarIcon className="h-5 w-5 mr-2" /> Schedule update
                </div>
                <SmallCardsRadio
                  options={[
                    {
                      name: `${
                        currentPriceIndex && subOption > currentPriceIndex
                          ? 'Upgrade'
                          : 'Downgrade'
                      }  now`,
                      checked: false,
                      tag: 'now',
                    },
                    {
                      name: `${
                        currentPriceIndex && subOption > currentPriceIndex
                          ? 'Upgrade'
                          : 'Downgrade'
                      }  end of this billing period`,
                      checked: false,
                      tag: 'later',
                    },
                  ]}
                  chosenTag={billedOption}
                  onChosen={(tag: string) => {
                    if (tag === 'now')
                      trackPlanChangePageEvents(
                        'Clicked change now in change plan page',
                      );
                    else if (tag === 'later')
                      trackPlanChangePageEvents(
                        'Clicked change end of billing period in change plan page',
                      );
                    setBilledOption(tag);
                  }}
                />
              </>
            ) : null}

            <div className="font-bold text-gray-600 flex items-center mt-3">
              <ShoppingBagIcon className="h-5 w-5 mr-2" /> Summary
            </div>
            <TotalTable
              currency={currency}
              items={totalTableItems}
              totalPrice={totalPrice}
              realPrice={realPrice}
              notBilledToday={billedOption === 'later'}
              nextBilling={moment(nextBilling).format('MMMM DD, YYYY')}
            />
          </div>
        ) : null}
      </div>
      {subOption !== undefined ? (
        <div className="mt-2">
          <div>
            <PrimaryButton
              text={`${
                currentPriceIndex
                  ? currentPriceIndex && subOption > currentPriceIndex
                    ? 'Upgrade to'
                    : 'Downgrade to'
                  : 'Subscribe'
              } to ${tiers[subOption].name} per 4 weeks plan`}
              onClick={onUpgrade}
              loading={loading}
            />
          </div>
          <div className="mt-2">
            <SecondaryBlueButton
              text="Choose different learning plan"
              onClick={() => {
                trackPlanChangePageEvents(
                  'Clicked choose different learning plan in change plan page',
                );
                setSubOption(undefined);
              }}
            />
          </div>
          <div className="text-center text-red-warning">{error}</div>
        </div>
      ) : null}
    </div>
  );
};
