import axios from 'axios';
import { CEFRLevel } from '../types/level';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/learningPlans'
    : 'http://localhost:8080/learningPlans'; // Dev env

export async function getAllLearningPlans() {
  const data = await axios.get(`${endPoint}/get`);
  return data?.data?.data;
}
