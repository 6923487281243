import mixpanel from '../../analytics/mixpanel';

type Events = 'Progress page: visited';

type ProfilePageData = {
  userID?: string;
};

export const trackProgressPageAnalytics = (
  event: Events,
  data: ProfilePageData = {},
) => {
  mixpanel.track(event, data);
};
