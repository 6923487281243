import cx from 'classix';
import { RadialProgress } from '../../progress/radialProgress';
import { ForwardRefExoticComponent, SVGProps } from 'react';
import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';

type LearningPathCardColorTheme =
  | 'green'
  | 'blue'
  | 'yellow'
  | 'orange'
  | 'red';

type LearningPathThemeToColorMap = {
  [key in LearningPathCardColorTheme]: string;
};

const mainThemeToColorMap: LearningPathThemeToColorMap = {
  green: '#22c55e',
  blue: '#3b82f6',
  yellow: '#eab308',
  red: '#ef4444',
  orange: '#f97316',
};

const subThemeToColorMap: LearningPathThemeToColorMap = {
  green: '#f0fdf4',
  blue: '#dbeafe',
  yellow: '#fef9c3',
  red: '#fee2e2',
  orange: '#ffedd5',
};

const thirdThemeToColorMap: LearningPathThemeToColorMap = {
  green: '#86efac',
  blue: '#93c5fd',
  yellow: '#fde047',
  red: '#fca5a5',
  orange: '#fdba74',
};

export const LearningPathCardVertical = (props: {
  colorTheme: LearningPathCardColorTheme;
  title: string;
  description: string;
  completed: number;
  needed: number;
  Icon: ForwardRefExoticComponent<Pick<SVGProps<SVGSVGElement>, any>>;
  onSuggestionClick?: Function;
}) => {
  const {
    colorTheme,
    title,
    description,
    completed,
    needed,
    onSuggestionClick,
    Icon,
  } = props;

  return (
    <div
      onClick={() => {
        onSuggestionClick?.();
      }}
      className={`relative rounded-md px-3 pt-3 pb-2 flex flex-col items-center min-w-[150px] cursor-pointer`}
      style={{
        backgroundColor: subThemeToColorMap[colorTheme],
      }}
    >
      <div
        className={'p-3 rounded-md max-h-14 max-w-14 absolute left-0 top-0'}
        style={{
          backgroundColor: subThemeToColorMap[colorTheme],
        }}
      >
        <Icon
          className={'w-5 h-5'}
          style={{
            color: mainThemeToColorMap[colorTheme],
          }}
        />
      </div>
      <RadialProgress
        percentage={Math.round((completed / needed) * 100)}
        color={mainThemeToColorMap[colorTheme]}
        backgroundColor={thirdThemeToColorMap[colorTheme]}
      />
      <div
        className={`text- font-semibold mt-3 text-left mr-auto mb-4`}
        style={{
          color: mainThemeToColorMap[colorTheme],
        }}
      >
        {title}
      </div>
      <div
        className={'font-semibold text-xs w-full mt-auto flex justify-between'}
        style={{
          color: mainThemeToColorMap[colorTheme],
        }}
      >
        {`${completed}/${needed} completed`}
        <ArrowRightIcon className="w-5 h-5" />
      </div>
    </div>
  );
};
