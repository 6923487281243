import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/progress'
    : 'http://localhost:8080/progress'; // Dev env

export async function getUserProgress(uid: string) {
  const data = await axios.get(`${endPoint}/${uid}`);
  return data?.data?.data;
}
