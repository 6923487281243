import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/slack'
    : 'http://localhost:8080/slack'; // Dev env

const customerNotifier = 'C06GCSU1EV8';
const guestNotifier = 'C07MNT3SY2J';

export async function slackCustomerNotifier(text: string) {
  const data = await axios.post(endPoint + '/message', {
    text,
    channel: customerNotifier,
  });
  return data?.data;
}

export async function slackGuestCreationNotifer(text: string) {
  const data = await axios.post(endPoint + '/message', {
    text,
    channel: guestNotifier,
  });
  return data?.data;
}
