import { useEffect, useState } from 'react';
import { getUserProgress } from '../../../api/progress';
import { BasicStatBox } from '../basicStatBox/basicStatBox';

export const MainStatBox = (props: { uid?: string; onSeeMore?: Function }) => {
  const { uid, onSeeMore } = props;

  const [weeklyProgressData, setWeeklyProgressData] = useState<
    Array<any> | undefined
  >();
  const [monthlyProgressData, setMonthlyProgressData] = useState<
    Array<any> | undefined
  >();
  const [currentWeekData, setCurrentWeekData] = useState<any | undefined>();
  const [previousWeekData, setPreviousWeekData] = useState<any | undefined>();

  const [loading, setLoading] = useState(false);

  async function updateUserProgress(uid: string) {
    setLoading(true);

    const progressData = await getUserProgress(uid);

    const weekDataList = progressData?.weekDataList;
    const monthDataList = progressData?.monthDataList;

    setWeeklyProgressData(weekDataList);
    setMonthlyProgressData(monthDataList);

    setPreviousWeekData(weekDataList?.[weekDataList?.length - 2]);
    setCurrentWeekData(weekDataList?.[weekDataList?.length - 1]);

    console.log(progressData);
    setLoading(false);
  }

  useEffect(() => {
    if (uid) updateUserProgress(uid);
  }, [uid]);

  return (
    <div className="grid md:grid-cols-3 gap-2">
      {currentWeekData ? (
        <>
          <BasicStatBox
            statArray={[
              {
                name: 'Avg # of words spoken',
                stat: `${currentWeekData?.numberOfWords?.toFixed(2)}`,
                unit: `from ${
                  previousWeekData?.numberOfWords?.toFixed(2) ?? 0
                }`,
                change: (
                  currentWeekData?.numberOfWords -
                  previousWeekData?.numberOfWords
                )?.toFixed(2),
                changeType:
                  currentWeekData?.numberOfWords -
                    (previousWeekData?.numberOfWords ?? 0) >=
                  0
                    ? 'increase'
                    : 'decrease',
                subText: (
                  currentWeekData?.numberOfWords -
                  (previousWeekData?.numberOfWords ?? 0)
                )?.toFixed(2),
                onSeeMore: onSeeMore
                  ? () => {
                      onSeeMore?.('Avg # of words spoken');
                    }
                  : undefined,
              },
            ]}
          />
          <BasicStatBox
            statArray={[
              {
                name: 'Avg # of advanced words spoken',
                stat: currentWeekData?.numberOfAdvancedWords?.toFixed(2),
                unit: `from ${
                  previousWeekData?.numberOfAdvancedWords?.toFixed(2) ?? 0
                }`,
                change: (
                  currentWeekData?.numberOfAdvancedWords -
                  previousWeekData?.numberOfAdvancedWords
                )?.toFixed(2),
                changeType:
                  currentWeekData?.numberOfAdvancedWords -
                    (previousWeekData?.numberOfAdvancedWords ?? 0) >=
                  0
                    ? 'increase'
                    : 'decrease',
                subText: (
                  currentWeekData?.numberOfAdvancedWords -
                  (previousWeekData?.numberOfAdvancedWords ?? 0)
                )?.toFixed(2),
                onSeeMore: onSeeMore
                  ? () => {
                      onSeeMore?.('Avg # of advanced words spoken');
                    }
                  : undefined,
              },
            ]}
          />
          <BasicStatBox
            statArray={[
              {
                name: 'Avg # of mistakes/sentences ratio',
                stat: currentWeekData?.sentenceToCorrectionRatio?.toFixed(2),
                unit: `from ${
                  previousWeekData?.sentenceToCorrectionRatio?.toFixed(2) ?? 0
                }`,
                change: (
                  currentWeekData?.sentenceToCorrectionRatio -
                  (previousWeekData?.sentenceToCorrectionRatio ?? 0)
                )?.toFixed(2),
                changeType:
                  currentWeekData?.sentenceToCorrectionRatio -
                    (previousWeekData?.sentenceToCorrectionRatio ?? 0) >=
                  0
                    ? 'increase'
                    : 'decrease',
                subText: (
                  currentWeekData?.sentenceToCorrectionRatio -
                  (previousWeekData?.sentenceToCorrectionRatio ?? 0)
                )?.toFixed(2),
                oppTheme: true,
                onSeeMore: onSeeMore
                  ? () => {
                      onSeeMore?.('Avg # of mistakes/sentences ratio');
                    }
                  : undefined,
              },
            ]}
          />
        </>
      ) : null}
    </div>
  );
};
