import { saveAs } from 'file-saver';
import { useEffect, useRef } from 'react';
import RecordRTC, {
  RecordRTCPromisesHandler,
  StereoAudioRecorder,
} from 'recordrtc';

export const BackgroundRecorder = (props: {
  isRecording: boolean;
  isReadyToInitializeRecorder: boolean;
  defaultDeviceId?: string;
  onRecorderReady?: Function;
  onDataAvailable?: Function;
  onStartRecording?: Function;
  onStopRecording?: Function;
  onDenied?: Function;
}) => {
  const recordRef = useRef<RecordRTC>();

  const {
    isRecording,
    isReadyToInitializeRecorder,
    onRecorderReady,
    onDataAvailable,
    onStartRecording,
    onStopRecording,
    defaultDeviceId,
    onDenied,
  } = props;

  async function initializeRecorderHandler() {
    let recordAudio: RecordRTC;
    if (!recordRef.current) {
      try {
        await navigator.mediaDevices
          .getUserMedia({
            video: false,
            audio: defaultDeviceId ? { deviceId: defaultDeviceId } : true,
          })
          .then((stream: any) => {
            // @ts-ignore
            recordAudio = new RecordRTCPromisesHandler(stream, {
              type: 'audio',
              mimeType: 'audio/wav', // sampleRate option is the "device's sample rate", use desiredSampleRate to convert sampling frequency
              desiredSampRate: 16000, // This sampling rate has to match with the sample rate to Speechmatic
              recorderType: StereoAudioRecorder,
              numberOfAudioChannels: 1,
              timeSlice: 2000,
              ondataavailable: async function (blob: Blob) {
                onDataAvailable?.(blob);
              },
            });
            recordRef.current = recordAudio;
            onRecorderReady?.(stream.getTracks());
          });
      } catch (err) {
        console.log(err);
        onDenied?.(err);
      }
    } else {
      recordRef.current.resumeRecording();
    }
  }

  async function startRecordingHandler() {
    if (recordRef.current) {
      console.log('Started recording..');
      recordRef.current.startRecording();
      onStartRecording?.();
    }
  }

  async function stopRecordingHandler() {
    try {
      if (recordRef.current) await recordRef.current.stopRecording();
      const recordingFile = recordRef.current
        ? await returnRecordingFile()
        : undefined;
      await onStopRecording?.(recordingFile);
    } catch (err) {
      onDenied?.(err);
      console.log('Recording failed');
    }
  }

  async function returnRecordingFile(): Promise<File | undefined> {
    if (!recordRef?.current) return undefined;
    const blob: Blob = await recordRef.current.getBlob();
    const recordingFile = new File([blob], 'recording.wav', {
      type: 'video/webm;codecs=vp9',
    });
    return recordingFile;
  }

  async function saveRecording() {
    if (!recordRef?.current) return;
    const blob: Blob = await recordRef.current.getBlob();
    const recordingFile = new File([blob], 'recording.wav', {
      type: 'video/webm;codecs=vp9',
    });
    saveAs(recordingFile);
  }

  useEffect(() => {
    if (isReadyToInitializeRecorder) initializeRecorderHandler();
  }, [isReadyToInitializeRecorder]);

  useEffect(() => {
    console.log(isRecording);
    if (isRecording && recordRef?.current) {
      startRecordingHandler();
    } else if (!isRecording && recordRef?.current) {
      // saveRecording(); For test
      stopRecordingHandler();
    }

    if (isRecording && !recordRef?.current)
      onDenied?.(`recordRef.current ${recordRef?.current}`);
  }, [isRecording]);

  return <></>;
};
