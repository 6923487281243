import { useEffect, useState } from 'react';
import { UserData } from '../../types/user';
import { useSelector } from 'react-redux';
import { getUserProgress } from '../../api/progress';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { BasicStatBox } from '../../components/stats/basicStatBox/basicStatBox';
import { MainStatBox } from '../../components/stats/mainStatBox/mainStatBox';
import { ProgressMainStatLoader } from '../../features/Progress/loaders/progressMainStatLoader';
import BlueAlert from '../../components/alerts/blueAlert';
import { trackProgressPageAnalytics } from '../../features/Progress/analytics';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const lineColor = 'rgb(0,158,255)';
const dotColor = 'rgba(0,158,255,0.5)';

export const ProgressPage = (props: {}) => {
  const [weeklyProgressData, setWeeklyProgressData] = useState<
    Array<any> | undefined
  >();
  const [monthlyProgressData, setMonthlyProgressData] = useState<
    Array<any> | undefined
  >();
  const [loading, setLoading] = useState(false);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  async function updateUserProgress(uid: string) {
    setLoading(true);

    const progressData = await getUserProgress(uid);

    setWeeklyProgressData(progressData?.weekDataList);
    setMonthlyProgressData(progressData?.monthDataList);

    console.log(progressData);
    setLoading(false);
  }

  useEffect(() => {
    trackProgressPageAnalytics('Progress page: visited');
  }, []);

  useEffect(() => {
    if (userData?.id && !weeklyProgressData) updateUserProgress(userData.id);
  }, [userData]);
  const labels = weeklyProgressData?.map((d) => d.weekFirstDate);

  return (
    <div>
      {loading ? (
        <ProgressMainStatLoader />
      ) : weeklyProgressData && weeklyProgressData?.length > 0 ? (
        <MainStatBox uid={userData?.id} />
      ) : (
        <BlueAlert
          text={`We don't have your class data yet. Start taking classes to see your progress here!`}
        />
      )}

      <div className="grid grid-cols-2 gap-2 mt-2">
        <div className="rounded-lg bg-white shadow p-4">
          <Line
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Avg # of words spoken per class',
                },
              },
            }}
            data={{
              labels,
              datasets: [
                {
                  label: 'Total # of words',
                  data: weeklyProgressData?.map((d) => d.numberOfWords),
                  borderColor: lineColor,
                  backgroundColor: dotColor,
                },
              ],
            }}
          />
        </div>
        <div className="rounded-lg bg-white shadow p-4">
          <Line
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Number of advanced words',
                },
              },
            }}
            data={{
              labels,
              datasets: [
                {
                  label: 'Total # of words',
                  data: weeklyProgressData?.map((d) => d.numberOfAdvancedWords),
                  borderColor: lineColor,
                  backgroundColor: dotColor,
                },
              ],
            }}
          />
        </div>
        <div className="rounded-lg bg-white shadow p-4">
          <Line
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Sentence to correction ratio',
                },
              },
            }}
            data={{
              labels,
              datasets: [
                {
                  label: '# of corrections / # of sentences spoken',
                  data: weeklyProgressData?.map(
                    (d) => d.sentenceToCorrectionRatio,
                  ),
                  borderColor: lineColor,
                  backgroundColor: dotColor,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};
