import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { PodHistory } from '../../components/podhistory/index';
import { ProfilePerformance } from '../../components/profile/profileperformance/index';
import { getObscuredName } from '../../util/standardization';
import {
  returnMeetingSessionDocByUID,
  returnUserByUID,
} from '../../firebase/configuration';
import { SocialShare } from '../../components/profile/socialshare/index';
import { PrimaryButton } from '../../components/buttons/primary';
import {
  analyticsProfilePagesVisited,
  analyticsUserProfilePageVisited,
} from '../../analytics';
import { ProfilePicture } from '../../components/profile/profilepicture';
import ReactTooltip from 'react-tooltip';
import { chronoMapToStreakCount, countryCodeToEmoji } from '../../util/helper';
import { analyticsProfilePictureUploaded } from '../../analytics/index';
import { ProfileReferral } from '../../components/profile/referral';
import { LoadingOverlay } from '../../components/loading';
import { checkIsAdmin } from '../../util/admin';
import { UserData } from '../../types/user';
import { SMSOptIn } from '../../features/SMSOptIn/SMSOptIn';
import { SubscriptionInformation } from '../../components/profile/subscriptioninformation';
import ProfileTable from '../../components/tables/profileTable';
import moment from 'moment-timezone';
import { getUserBookingsAttended } from '../../firebase/subscription/bookings/bookings';
import {
  getUserFollowed,
  getUserFollowings,
} from '../../firebase/social/social';
import { FollowButton } from '../../components/buttons/follow/followButton';
import { followUser, unfollowUser } from '../../api/social';
import { trackProfilePageAnalytics } from '../../features/ProfilePage/analytics';

const fireEmojiUrl =
  'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/website_photos%2Femojis%2Ffire_emoji.png?alt=media&token=38833c15-d14b-4f5c-b703-70ee7b8db6e7';

type ProfilePageComponentProps = {
  userData: UserData;
  onSetUserData: any;
};

const ProfilePageComponent = ({
  userData,
  onSetUserData,
}: ProfilePageComponentProps) => {
  const userID = window.location.pathname.split('/')[2];
  const currentUrl = 'http://app.immigo.io' + window.location.pathname;
  const [student, setStudentState] = useState(userData); // Why is this default to your own data?

  const [loadingText, setLoadingText] = useState<string>(
    'Please wait a moment :)',
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [meetingSessionMap, setMeetingSessionMap] = useState<Array<any>>([]);
  const [totalSessionTime, setTotalSessionTime] = useState<number>(0);
  const isExternalUserVisitingProfile: boolean =
    userData === null || (userData && userData.id !== userID);

  console.log(isExternalUserVisitingProfile);
  const isAdmin = checkIsAdmin(userData?.email);

  const [userFollowings, setUserFollowings] = useState<
    Array<UserData> | undefined
  >();
  const [userFollowed, setUserFollowed] = useState<
    Array<UserData> | undefined
  >();
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    analyticsProfilePagesVisited();
    if (userData)
      analyticsUserProfilePageVisited(userID, isExternalUserVisitingProfile);
  }, [userData]);

  useEffect(() => {
    if (userData && userID) updateStudentData(userID);
  }, [userID, userData]);

  async function updateStudentData(userid: string) {
    const studentData: any = await returnUserByUID(userid);
    const studentBookings = await getUserBookingsAttended(userid);
    const userFollowings = (await getUserFollowings(userid)) as Array<UserData>;
    const userFollowed = (await getUserFollowed(userid)) as Array<UserData>;

    studentData.bookings = studentBookings;
    setStudentState(studentData);

    setUserFollowed(userFollowed);
    setUserFollowings(userFollowings);

    let followFlag = false;
    for (let i = 0; i < userFollowed.length; i++) {
      if (userFollowed[i].id === userData?.id) {
        followFlag = true;
        break;
      }
    }
    setIsFollowing(followFlag);

    if (studentData.meetingSessionMap) {
      const sessionMap: any = studentData.meetingSessionMap;
      let totalTime = 0;
      const retrievedSessionMap: any = {};
      await Promise.all(
        Object.keys(sessionMap).map(async (courseID: string) => {
          const sessionIDs = Array.from(new Set<string>(sessionMap[courseID]));
          const sessionDocs = await Promise.all(
            sessionIDs.map(async (sessionID: string) => {
              const sessionData = await returnMeetingSessionDocByUID(sessionID);
              if (
                sessionData?.userTimeData &&
                sessionData?.userTimeData[userID]
              ) {
                totalTime += sessionData?.userTimeData[userID];
              }
              return sessionData;
            }),
          );
          retrievedSessionMap[courseID] = sessionDocs;
        }),
      );
      setMeetingSessionMap(retrievedSessionMap);
      setTotalSessionTime(totalTime);
    }
  }

  async function onFollow() {
    if (!isFollowing) {
      trackProfilePageAnalytics('Profile page: user followed');
      await followUser(userData.id, userID);
    } else {
      trackProfilePageAnalytics('Profile page: user unfollowed');
      await unfollowUser(userData.id, userID);
    }
    await updateStudentData(userID);
  }

  return (
    <div className="flex w-full justify-center">
      <LoadingOverlay enabled={submitting} />
      {student ? (
        <div className="pt-24 px-4 lg:px-0 w-full">
          <div className="flex flex-col justify-center items-center">
            <ProfilePicture
              onPictureUpload={() => {
                setSubmitting(true);
              }}
              onPictureSubmitted={() => {
                analyticsProfilePictureUploaded();
                setSubmitting(false);
                window.location.reload();
              }}
              onProgress={(progress: number) => {
                setLoadingText(
                  'Uploading in progress ... ' + Math.round(progress) + '%',
                );
              }}
              currentStudent={student}
            />
            <div className="flex flex-col align-center justify-center pt-10">
              <div className="flex">
                <h1 className="text-3xl font-bold">
                  {getObscuredName(student.name)}
                </h1>
                <img
                  className="w-8 ml-4 rounded-sm"
                  src={student ? countryCodeToEmoji(student.nationality) : ''}
                  alt={'flag'}
                />
              </div>

              {student &&
              chronoMapToStreakCount(student.chronoAttendanceMapByWeek) > 0 ? (
                <div
                  data-tip={
                    getObscuredName(student.name) +
                    ' has attended pod meetings ' +
                    chronoMapToStreakCount(student.chronoAttendanceMapByWeek) +
                    ' weeks in a row!'
                  }
                  className="pr-4 flex bg-red-warm rounded-md w-16 h-7 mx-auto my-2"
                >
                  <img
                    className="h-4 w-4 m-auto"
                    src={fireEmojiUrl}
                    alt="streak"
                  />
                  <ReactTooltip
                    className="font-bold rounded-md"
                    backgroundColor="#009eff"
                  />
                  <span className="m-auto font-bold text-white">
                    {chronoMapToStreakCount(student.chronoAttendanceMapByWeek)}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="flex mt-2">
              <div className="flex mr-4">
                <span className="font-bold mr-1">
                  {userFollowings?.length ?? 0}
                </span>
                <span className="text-gray-500">Following</span>
              </div>
              <div className="flex">
                <span className="font-bold mr-1">
                  {userFollowed?.length ?? 0}
                </span>
                <span className="text-gray-500">Followers</span>
              </div>
            </div>
            {isExternalUserVisitingProfile ? (
              <div className="mt-2">
                <FollowButton onClick={onFollow} followed={isFollowing} />
              </div>
            ) : null}
            <div className="pt-10 w-auto">
              {/* Social Share */}
              {isExternalUserVisitingProfile ? (
                <div className="bg-white rounded-md py-2 px-12">
                  <ProfileTable
                    joined={moment(student.createdAt).format('MMMM Do YYYY')}
                    occupation={student.occupation}
                    numberOfClasses={student.bookings?.length ?? 0}
                    infoToKnow={student.infoToKnow}
                    coach={student.coach}
                  />
                </div>
              ) : (
                <SocialShare url={currentUrl} userID={userID} />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const ProfilePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfilePageComponent);
