import cx from 'classix';

export interface BasicStatusTableElementObject {
  id: string | number;
  status: string;
  text: string;
  secondText?: string;
  statusText: string;
  description: string;
  secondDescription?: string;
}

const statuses: any = {
  gray: 'text-gray-500 bg-gray-100/10',
  green: 'text-green-400 bg-green-400/10',
  blue: 'text-blue-400 bg-blue-400/10',
  rose: 'text-rose-400 bg-rose-400/10',
};

const environments: any = {
  gray: 'text-gray-500 bg-gray-400/10 ring-gray-400/10',
  green: 'text-green-400 bg-green-400/10 ring-green-400/10',
  blue: 'text-blue-400 bg-blue-400/10 ring-blue-400/10',
  rose: 'text-rose-400 bg-rose-400/10 ring-rose-400/10',
};

export default function BasicStatusTableElement(
  props: BasicStatusTableElementObject,
) {
  const {
    id,
    status,
    statusText,
    text,
    secondText,
    description,
    secondDescription,
  } = props;
  return (
    <li key={id} className="relative flex items-center space-x-4 py-4">
      <div className="min-w-0 flex-auto">
        <div className="flex items-center gap-x-3">
          <div className={cx(statuses[status], 'flex-none rounded-full p-1')}>
            <div className="h-2 w-2 rounded-full bg-current" />
          </div>
          <h2 className="min-w-0 text-sm font-semibold leading-6 text-black">
            <div className="flex gap-x-2">
              <span className="truncate">{text}</span>
              <span className="text-gray-400">/</span>
              <span className="whitespace-nowrap">{secondText}</span>
              <span className="absolute inset-0" />
            </div>
          </h2>
        </div>
        <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
          <p className="truncate">{description}</p>
          <svg
            viewBox="0 0 2 2"
            className="h-0.5 w-0.5 flex-none fill-gray-300"
          >
            <circle r={1} cx={1} cy={1} />
          </svg>
          <p className="whitespace-nowrap">{secondDescription}</p>
        </div>
      </div>
      <div
        className={cx(
          environments[status],
          'flex-none rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset',
        )}
      >
        {statusText}
      </div>
    </li>
  );
}
