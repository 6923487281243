import { useEffect, useRef, useState } from 'react';
import { cx } from 'classix';
import BookingEventContainer, {
  BookingEventObject,
} from './bookingEventContainer';
import moment from 'moment';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  getClassSessionBookings,
  getClassSessionDocByStartMili,
  getClassWeekTopic,
} from '../../firebase/subscription/subscription';
import { convertMiliTimeToChosenWeekTime } from '../../util/dates';
import { BookingEventContainerTimeGrid } from './bookingEventContainerTimeGrid/bookingEventContainerTimeGrid';
import { BookingEventContainerStacked } from './bookingEventContainerStacked/bookingEventContainerStacked';
import { maxClassSize } from '../../config/classes';
import { getIsClassRecommended } from '../../api/bookings';

const limit24hours = 1000 * 60 * 60 * 24;

export default function WeekBookingCalendar(props: {
  events: Array<BookingEventObject>;
  setChosenSchedule: Function;
  chosenSchedule: Array<BookingEventObject>;
  setLoading: Function;
  bookedClassSessionIds?: Array<string>;
  cefr?: string;
}) {
  const {
    events,
    setChosenSchedule,
    chosenSchedule,
    setLoading,
    bookedClassSessionIds,
    cefr,
  } = props;
  const [chosenDateMoment, setChosenDateMoment] = useState(moment());
  const [firstDayCalendar, setFirstDayCalendar] = useState(moment());
  const [currentClassSessions, setCurrentClassSessions] = useState<
    Array<BookingEventObject>
  >([]);

  const weekDayMoments = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) =>
    firstDayCalendar.clone().add(dayIndex, 'd'),
  );

  const earliestMiliChosenWeek = firstDayCalendar
    .clone()
    .set('h', 0)
    .set('m', 0)
    .set('s', 0)
    .set('ms', 0)
    .valueOf(); // 0th hour minute and second today

  const classSizeLimit = maxClassSize;

  async function updateClassSessions() {
    setLoading(true);
    const classCopy = events.map((c) => {
      return { ...c };
    }); // Copy the class list so that it doesn't change the original copy
    let newClassSessions = await Promise.all(
      classCopy.map(async (c: BookingEventObject, index) => {
        const topic =
          c.id && c.recurring
            ? await getClassWeekTopic(
                c.id,
                Math.max(
                  convertMiliTimeToChosenWeekTime(
                    c.startMili,
                    firstDayCalendar.valueOf(),
                  ),
                  c.startMili,
                ),
              )
            : c.title;
        c.title = topic;
        c.startMili = c.recurring
          ? Math.max(
              convertMiliTimeToChosenWeekTime(
                c?.startMili,
                firstDayCalendar.valueOf(),
              ),
              c.startMili,
            )
          : c.startMili;
        if (c?.reschedule?.[c.startMili] !== undefined) {
          if (c?.reschedule?.[c.startMili] > 0)
            c.startMili = c?.reschedule?.[c.startMili]; // rescheduled schedule
          else c.disabled = true; // Disable the class if it has exception
        }

        c.recommended =
          c.recLevel && cefr
            ? c.recLevel.split('').includes(cefr) || c.recLevel === 'all'
            : true;

        const bookings = c.id
          ? await getClassSessionBookings(c.id, c.startMili)
          : [];
        const sessionDoc = c.id
          ? await getClassSessionDocByStartMili(c.id, c.startMili)
          : undefined;
        if (sessionDoc?.id) c.classSessionID = sessionDoc.id;
        c.bookings = bookings.filter((b) => !b.canceled);
        c.disabled =
          c.canceled ||
          c.disabled ||
          (new Date().valueOf() + limit24hours >= c.startMili &&
            c.bookings?.length === 0) ||
          new Date().valueOf() >= c.startMili ||
          bookedClassSessionIds?.includes(c?.classSessionID ?? '') ||
          c.bookings?.length > classSizeLimit; // If there's already a booking and happens outside of 24 hours window
        c.full = c.bookings?.length > classSizeLimit && !c.noCap;
        return c;
      }),
    );

    newClassSessions = newClassSessions.filter((sesh: BookingEventObject) => {
      return (
        sesh.startMili <=
          weekDayMoments[6]
            .clone()
            .set('h', 23)
            .set('m', 0)
            .set('s', 0)
            .set('ms', 0)
            .valueOf() && // Last day hour minute of the week
        moment(sesh.startMili)
          .set('h', 0)
          .set('m', 0)
          .set('s', 0)
          .set('ms', 0)
          .valueOf() >= earliestMiliChosenWeek
      );
    });

    setCurrentClassSessions(newClassSessions);
    setLoading(false);
  }

  useEffect(() => {
    if (events.length > 0) updateClassSessions();
  }, [events, firstDayCalendar]);

  return (
    <div className="flex h-full flex-col">
      <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          <time dateTime="2022-01">{`${weekDayMoments[0].format(
            'MMM DD',
          )} - ${weekDayMoments[6].format('MMM DD')} ${weekDayMoments[6].format(
            'YYYY',
          )}`}</time>
        </h1>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <div
              className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
              aria-hidden="true"
            />
            <button
              onClick={() => {
                setCurrentClassSessions([]);
                setFirstDayCalendar(firstDayCalendar.clone().subtract(1, 'w'));
                setChosenDateMoment(chosenDateMoment.clone().subtract(1, 'w'));
              }}
              type="button"
              className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous week</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
            >
              {`${weekDayMoments[0].format(
                'MMM DD',
              )} - ${weekDayMoments[6].format('MMM DD')}`}
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              onClick={() => {
                setCurrentClassSessions([]);
                setFirstDayCalendar(firstDayCalendar.clone().add(1, 'w'));
                setChosenDateMoment(chosenDateMoment.clone().add(1, 'w'));
              }}
              type="button"
              className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next week</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </header>
      {/* <BookingEventContainerTimeGrid
        currentClassSessions={currentClassSessions}
        weekDayMoments={weekDayMoments}
        chosenDateMoment={chosenDateMoment}
        chosenSchedule={chosenSchedule}
        setChosenSchedule={setChosenSchedule}
        setChosenDateMoment={setChosenDateMoment}
      /> */}
      <BookingEventContainerStacked
        currentClassSessions={currentClassSessions}
        weekDayMoments={weekDayMoments}
        chosenDateMoment={chosenDateMoment}
        chosenSchedule={chosenSchedule}
        setChosenSchedule={setChosenSchedule}
        setChosenDateMoment={setChosenDateMoment}
      />
    </div>
  );
}
