import { GuestPass } from '../types/guestPass';

export function filterValidPasses(passes: Array<GuestPass>) {
  return passes.filter((pass) => pass.status === 'unused');
}

export function filterUsedPasses(passes: Array<GuestPass>) {
  return passes.filter((pass) => pass.status === 'used');
}

export function sortByExpirationDate(passes: Array<GuestPass>) {
  return passes.sort((p1, p2) => p1.expires - p2.expires);
}

export function getValidPassExpiringSoonest(passes: Array<GuestPass>) {
  const filtered = filterValidPasses(passes);
  const sorted = sortByExpirationDate(filtered);
  return sorted?.[0];
}
