import { useEffect, useRef, useState } from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { SpeechMaticTranscriptResult } from '../../types/speechmatic';

export const BackupBrowserTranscriber = (props: {
  activate: boolean;
  onFinalTranscript: Function;
  onError?: Function;
}) => {
  const { activate, onFinalTranscript, onError } = props;

  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [lastTextTime, setLastTextTime] = useState<undefined | number>();
  const [lastTextSecond, setLastTextSecond] = useState(0);
  const [currentText, setCurrentText] = useState<undefined | string>();

  const timerRef = useRef<NodeJS.Timeout>();

  const startListening = () =>
    SpeechRecognition.startListening({ continuous: true, language: 'en-US' });

  const stopListening = () => SpeechRecognition.stopListening();

  const {
    transcript,
    finalTranscript,
    interimTranscript,
    resetTranscript,
    browserSupportsSpeechRecognition,
    listening,
  } = useSpeechRecognition();

  useEffect(() => {
    const processedString = `${lastTextTime}-${lastTextSecond}-${elapsedSeconds}-S1:${finalTranscript}`;
    setLastTextSecond(elapsedSeconds);
    setCurrentText(finalTranscript);
    setLastTextTime(new Date().valueOf());
    if (finalTranscript.length > 3) onFinalTranscript(processedString);
    resetTranscript();
  }, [finalTranscript]);

  useEffect(() => {
    if (activate) {
      console.log('activating browser transcriber');
      setLastTextTime(new Date().valueOf());
      startListening();
      timerRef.current = setInterval(() => {
        setElapsedSeconds((prev) => prev + 1);
      }, 1000);
    }
    if (!activate && listening) {
      clearInterval(timerRef.current);
      stopListening();
    }
  }, [activate]);

  return <></>;
};
