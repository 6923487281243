import { useEffect, useState } from 'react';
import { CircularFloater } from './circularFloater';
import { trackLayOutEvent } from '../Layout/analytics';
import {
  addVideoStatus,
  getAllUserVideoStatus,
  updateVideoStatus,
} from '../../api/videoStatus';
import {
  UserVideoStatus,
  UserVideoStatusTag,
  UserVideoTypeTag,
} from '../../types/videoStatus';
import { useLocation } from 'react-router-dom';

export const VideoWidgetControler = (props: { uid: string }) => {
  const { uid } = props;
  const [videoFloaterOpen, setVideoFloaterOpen] = useState(false);
  const [videoType, setVideoType] = useState<undefined | UserVideoTypeTag>();
  const [videoStatus, setVideoStatus] = useState<
    undefined | UserVideoStatusTag
  >();
  const [videoUrl, setVideoUrl] = useState<undefined | string>();

  const location = useLocation();

  async function videoStatusActionHandler(statuses: Array<UserVideoStatus>) {
    const welcomeVideoStatus = statuses?.find(
      (status: UserVideoStatus) => status.id === 'welcome',
    );

    // No status yet then greet them
    if (
      (!welcomeVideoStatus ||
        (welcomeVideoStatus.status !== 'closed' &&
          welcomeVideoStatus.status !== 'ended')) &&
      location.pathname === '/home'
    ) {
      await addVideoStatus(uid, 'opened', 'welcome'); // Add status
      setVideoUrl(
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/app_clips%2Fryan-motivation.mp4?alt=media&token=429880dc-4601-44da-b580-b4f9593f4549',
      );
      setVideoStatus('opened');
      setVideoType('welcome');
      setVideoFloaterOpen(true);
    }
  }

  async function onLoad(uid: string) {
    const videoStatus = await getAllUserVideoStatus(uid);
    console.log(videoStatus);
    await videoStatusActionHandler(videoStatus);
  }

  useEffect(() => {
    if (uid) onLoad(uid);
  }, [uid]);

  return (
    <CircularFloater
      open={videoFloaterOpen}
      onClose={async () => {
        setVideoFloaterOpen(false);
        setVideoStatus('closed');
        if (videoStatus && videoType)
          await updateVideoStatus(uid, 'closed', videoType);
      }}
      videoUrl={videoUrl}
      onVideoPlay={async () => {
        trackLayOutEvent('Welcome video floater played'); // These need to change to variables when we add more video types
        setVideoStatus('played');
        if (videoStatus && videoType)
          await updateVideoStatus(uid, 'played', videoType);
      }}
      onVideoPaused={async () => {
        trackLayOutEvent('Welcome video floater paused');
      }}
      onVideoEnded={async () => {
        trackLayOutEvent('Welcome video floater ended');
        setVideoStatus('ended');
        if (videoStatus && videoType)
          await updateVideoStatus(uid, 'ended', videoType);
      }}
    />
  );
};
