import axios from 'axios';
import { UserVideoStatusTag, UserVideoTypeTag } from '../types/videoStatus';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/videoStatus'
    : 'http://localhost:8080/videoStatus'; // Dev env

export async function getVideoStatus(
  uid: string,
  videoStatusTag: UserVideoStatusTag,
) {
  const data = await axios.get(`${endPoint}/${uid}/${videoStatusTag}`);
  return data?.data?.data;
}

export async function getAllUserVideoStatus(uid: string) {
  const data = await axios.get(`${endPoint}/${uid}`);
  return data?.data?.data;
}

export async function addVideoStatus(
  uid: string,
  videoStatusTag: UserVideoStatusTag,
  videoTypeTag: UserVideoTypeTag,
) {
  const data = await axios.post(`${endPoint}/add`, {
    uid,
    videoStatusTag,
    videoTypeTag,
  });
  return data?.data;
}

export async function updateVideoStatus(
  uid: string,
  videoStatusTag: UserVideoStatusTag,
  videoTypeTag: UserVideoTypeTag,
) {
  const data = await axios.post(`${endPoint}/update`, {
    uid,
    videoStatusTag,
    videoTypeTag,
  });
  return data?.data;
}
