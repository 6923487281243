import { useSelector } from 'react-redux';
import { LearningPathCard } from '../../components/learningPath/learningPathCard/learningPathCard';
import { UserData } from '../../types/user';
import { getAllLearningPaths, startLearningPath } from '../../api/learningPath';
import { useEffect, useState } from 'react';
import { LearningPath } from '../../types/learningPath';
import { LearningPathCardLoader } from '../../components/learningPath/learningPathCard/loader/learningPathCardLoader';
import { trackLearningPathData } from '../../features/LearningPath/analytics';
import { SparkleButton } from '../../components/buttons/sparkle/sparkleButton';

export const LearningPathPage = (props: {}) => {
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const [learningPaths, setLearningPaths] = useState<
    Array<LearningPath> | undefined
  >();

  async function loadLearingPaths() {
    const learningPaths = await getAllLearningPaths();
    setLearningPaths(learningPaths);
    console.log(learningPaths);
  }

  useEffect(() => {
    loadLearingPaths();
    trackLearningPathData('Visited learning path page');
  }, []);

  return (
    <div className="flex flex-col justify-center">
      <div className="text-center text-4xl my-8">
        ✏️ Choose your learning path
      </div>
      <div className="max-w-[700px] text-xl text-gray-500 mx-auto mb-8 text-center">
        Choose the path that fits your needs. Each path is designed carefully by
        a group of English learning experts to guide you through your learning
        journey. You can always change your learning path if you change your
        mind.
      </div>

      <div className="grid md:grid-cols-2 gap-2">
        {learningPaths && userData ? (
          userData?.cefrLevel ? (
            learningPaths.map((path) => {
              return (
                <LearningPathCard
                  key={path.id}
                  title={path.name}
                  description={path.description}
                  onChosen={async () => {
                    console.log(path);
                    if (path.id) await startLearningPath(userData?.id, path.id);
                    trackLearningPathData('Chose learning path');
                    window.location.href = '/home';
                  }}
                />
              );
            })
          ) : (
            <>
              <div className="text-left ml-auto text-gray-600 max-w-[300px] my-20">
                Looks like you haven't finished your profile! Please complete
                your profile so that we can design your custom learning path for
                you.
              </div>
              <div className="mr-auto max-w-[300px] my-20">
                <SparkleButton
                  text="Complete my profile"
                  onClick={() => {
                    window.open('/get-started', '_blank');
                  }}
                />
              </div>
            </>
          )
        ) : (
          <>
            <LearningPathCardLoader />
            <LearningPathCardLoader />
          </>
        )}
      </div>
    </div>
  );
};
