import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../util/standardization';
import { PaymentMethodsModal } from '../../modals/paymentMethodsModal/paymentMethodsModal';
import { PaymentMethod } from '../../../types/payment';
import {
  detachPaymentMethod,
  getStripeCustomer,
  updateCustomerDefaultPaymentMethod,
} from '../../../api/payment';

export const PaymentMethodCardSimple = (props: {
  brand: string;
  lastFour: number;
  expMonth: string;
  expYear: string;
  customerID: string;
  returnUrl: string;
  onAction?: Function;
  onActionComplete?: Function;
}) => {
  const {
    brand,
    lastFour,
    expMonth,
    expYear,
    customerID,
    onAction,
    onActionComplete,
    returnUrl,
  } = props;

  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [customerData, setCustomerData] = useState<any>();

  async function onPaymentMethodsSave(paymentMethodID: string) {
    onAction?.();
    console.log('saving method');
    await updateCustomerDefaultPaymentMethod(customerID, paymentMethodID);
    onActionComplete?.();
    window.location.reload();
  }

  async function onPaymentMethodDelete(paymentMethodID: string) {
    onAction?.();
    await detachPaymentMethod(paymentMethodID);
    onActionComplete?.();
    window.location.reload();
  }

  async function updateCustomerData(customerID: string) {
    const cs = await getStripeCustomer(customerID);
    console.log(cs);
    setCustomerData(cs);
  }

  useEffect(() => {
    updateCustomerData(customerID);
  }, [customerID]);

  console.log(openPaymentMethodModal);

  return (
    <>
      {customerData ? (
        <PaymentMethodsModal
          paymentMethods={customerData?.paymentMethods.map((p: any) => {
            const card = p?.card;
            return {
              lastFour: card?.last4,
              brand: card?.brand,
              expMonth: card?.exp_month,
              expYear: card?.exp_year,
              id: p?.id,
            };
          })}
          returnUrl={returnUrl}
          isOpen={openPaymentMethodModal}
          onSave={onPaymentMethodsSave}
          onDelete={onPaymentMethodDelete}
          onClose={() => {
            setOpenPaymentMethodModal(false);
          }}
          defaultID={customerData?.invoice_settings?.default_payment_method}
        />
      ) : null}
      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <span className="flex justify-between">
          {brand && lastFour ? (
            <span>{`${capitalizeFirstLetter(brand ?? '')} •••• ${lastFour}  
          ${expMonth}/${expYear}`}</span>
          ) : null}
          <span
            onClick={() => {
              setOpenPaymentMethodModal(true);
            }}
            className="cursor-pointer border p-2 text-xs rounded-md hover:text-blue-immigo hover:border-blue-immigo "
          >
            {brand && lastFour
              ? `Change payment method`
              : `+ Add payment method`}
          </span>
        </span>
      </dd>
    </>
  );
};
