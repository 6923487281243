import axios from 'axios';
import { CEFRLevel } from '../types/level';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/discountCodes'
    : 'http://localhost:8080/discountCodes'; // Dev env

export async function getAllDiscountCodes() {
  const data = await axios.get(`${endPoint}/get`);
  return data?.data?.data;
}

export async function getDiscountCode(code: string) {
  const data = await axios.get(`${endPoint}/get/${code}`);
  return data?.data?.data;
}

export async function getPriceDiscount(priceId: string, codeId: string) {
  const data = await axios.get(
    `${endPoint}/getPriceDiscount/${priceId}/${codeId}`,
  );
  return data?.data?.data;
}
