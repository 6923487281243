import BasicStatusTableElement, {
  BasicStatusTableElementObject,
} from './basicStatusTableElement';

export default function BasicStatusTable(props: {
  items: Array<BasicStatusTableElementObject>;
}) {
  const { items } = props;
  return (
    <ul role="list" className="divide-y divide-white/5">
      {items.map((item: BasicStatusTableElementObject) => (
        <BasicStatusTableElement
          text={item.text}
          secondText={item.secondText}
          id={item.id}
          status={item.status}
          secondDescription={item.secondDescription}
          statusText={item.statusText}
          description={item.description}
        />
      ))}
    </ul>
  );
}
